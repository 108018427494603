import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';





function getStepContent(step) {
  switch (step) {
    case 0:
      return `Our development process starts with the consultation phase. We'll go through any questions you have and define clear goals with a timeline.  `;
    case 1:
      return `Our designers create UI mockups to work through the appearance of the finished product. Alternatively, we can work directly with your designer of choice.`;
    case 2:
      return `With defined goals and a clear vision of design & functionality, we begin the development phase. This phase can include beta-testing if applicable.`;
    case 3: 
        return `Your application goes live!`;
    case 4:
        return `We continue to provide support and regular updates.`;
    default:
      return 'Unknown step';
  }
}

export default function VerticalLinearStepper(props) {

  return (
    <div >
      <Stepper className="transparent" orientation="vertical">
          <Step active={true}>
            <StepLabel><h5 className={(props.darkMode) ? "dark" : "light"}>Consultation</h5></StepLabel>
            <StepContent>
              <Typography><p className={(props.darkMode) ? "dark" : "light"}>{getStepContent(0)}</p></Typography>
            </StepContent>
          </Step>
          <Step active={true}>
            <StepLabel><h5 className={(props.darkMode) ? "dark" : "light"}>Design</h5></StepLabel>
            <StepContent>
              <Typography><p className={(props.darkMode) ? "dark" : "light"}>{getStepContent(1)}</p></Typography>
            </StepContent>
          </Step>
          <Step active={true}>
            <StepLabel><h5 className={(props.darkMode) ? "dark" : "light"}>Development</h5></StepLabel>
            <StepContent>
              <Typography><p className={(props.darkMode) ? "dark" : "light"}>{getStepContent(2)}</p></Typography>
            </StepContent>
          </Step>
          <Step active={true}>
            <StepLabel><h5 className={(props.darkMode) ? "dark" : "light"}>Publication</h5></StepLabel>
            <StepContent>
              <Typography><p className={(props.darkMode) ? "dark" : "light"}>{getStepContent(3)}</p></Typography>
            </StepContent>
          </Step>
          <Step active={true}>
            <StepLabel><h5 className={(props.darkMode) ? "dark" : "light"}>Support</h5></StepLabel>
            <StepContent>
              <Typography><p className={(props.darkMode) ? "dark" : "light"}>{getStepContent(4)}</p></Typography>
            </StepContent>
          </Step>
      </Stepper>
    </div>
  );
}

