import React, { useState } from "react";
import CodeSharpIcon from '@material-ui/icons/CodeSharp';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from '@material-ui/core/Button';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import hljs from 'highlight.js/lib/highlight';
import swift from 'highlight.js/lib/languages/swift';
import Highlight from 'react-highlight.js'
import VerticalLineStepper from './Steps'
import Skill from './Skill';
import Footer from './Footer';
import ContactForm from './ContactForm';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

function Header() {
    hljs.registerLanguage('swift', swift);
    const [isDarkMode, setDarkMode] = useState(false);

    function toggleDark() {
        (!isDarkMode) ? setDarkMode(true) : setDarkMode(false);
        Header.setDarkMode = false
    }

    const skillDescription = {
        webDev: "From simple websites to complex web apps, I develop with strong sense of UI / UX and scalability.",
        iosDev: "iPhone mobile app development is my forté. With a deep understanding of the Apple ecosystem, I love developing iOS apps.",
        cloud: "As an official AWS Partner Consultant, I build backends on native cloud services and I help companies move their existing workloads into the cloud.",
        business: "Not every problem can be solved through software, but every company has a problem that can be.",
        ecom: "In this day and age, a strong e-commerce presence is an absolute must for any brand selling products or services.",
        ml: "Machine Learning can be an extraordinarily powerful tool in assisting businesses make complex decisions. Harness the power by including ML in your application.",
        ar: "Are you a brand interested in connecting with your consumers in new and exciting ways? AR might be just what your looking for."
    }

    return (
        <div className={(isDarkMode) ? "background-dark" : "background-light"}>
            <Navbar collapseOnSelect expand="lg" bg={(isDarkMode) ? "dark" : "light"} variant={(isDarkMode) ? "dark" : "light"} sticky="top">
                <Container>
                    <Navbar.Brand href="#"><CodeSharpIcon /> alphatier.io</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <AnchorLink className="nav-link" offset='100' href='#about'>About</AnchorLink>
                            <AnchorLink className="nav-link" offset='100' href='#specialties'>Specialties</AnchorLink>
                            <AnchorLink className="nav-link" offset='100' href='#contact'>Contact</AnchorLink>
                            <Nav.Link onClick={toggleDark}>{(isDarkMode) ? <WbSunnyIcon className="lm-icon"/> : <Brightness3Icon />}</Nav.Link>
                        </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>  
            <Container> 
              
                    <Row style={{height: "100vh", overflow: "auto"}}>
                        <Col xs={12} md={12} lg={6}>
                            <h1 className={(isDarkMode) ? "dark center-text" : "light center-text"}>
                                Full Service
                                <br/>
                                Web & Mobile App
                                <br/>
                                Development Agency
                            </h1>
                            <hr className="accent-line"></hr>
                            <br/>
                            <br/>
                            <h5 className={(isDarkMode) ? "dark" : "light"}>Consulting | Design | Development</h5>
                            <br/>
                            <Button variant="contained" style={(isDarkMode) ? {backgroundColor: "#F8F9FA", color: "#343A40"} : {backgroundColor: "#343A40", color: "#F8F9FA"}}>Inquire Now</Button>
                            </Col>
                        <Col xs={12}s md={12} lg={6} >
                        <img className="center-pic-vert" src={ require('../assets/app_dev.png') } />
                        </Col> 
                    </Row>

                    <section id="about">
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                                <h1 className={(isDarkMode) ? "dark" : "light"}>
                                    About
                                </h1>
                                <hr className="accent-line"></hr>
                        </Col>
                    </Row>
                    <Row className="content-row">
                        <Col xs={12} md={12} lg={6}>
                        <h5 className={(isDarkMode) ? "dark" : "light"}>
                            My name is Ian. I'm a freelance software engineer with a focus on solving business problems through software.
                            <br/>
                            <br/>
                            Based in beautiful Charleston, South Carolina, I create amazing web and mobile applications for my clients.
                            <br/>
                            <br/>
                            With expertise ranging from simple websites to complex & scalable iOS applications, I exceed my client's expectations in speed and accuracy.   
                        </h5>
                        </Col>
                        <Col xs={12} md={6} >
                            <Card style={{ backgroundColor: "#fff" }} className="right-row">
                                <CardContent>
                                    <Highlight language="swift">
                                        {"func alphatier(_ yourIdea: Idea) -> App {\n   let finishedApp = develop(yourIdea)\n   return finishedProduct\n\n}"}
                                    </Highlight>
                                </CardContent>
                              
                            </Card>
                        </Col> 
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <h1 className={(isDarkMode) ? "dark section-title" : "light section-title"}>
                                Process
                            </h1>
                            <hr className="accent-line"></hr>
                        </Col>
                    </Row>
                    
                    <Row className="content-row">
                        <Col xs={12} md={12} lg={12}>
                            <VerticalLineStepper darkMode={isDarkMode}/>
                        </Col>
                    </Row>
                    </section>
        
                    <section id="specialties">
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <h1 className={(isDarkMode) ? "dark section-title" : "light section-title"}>
                                Specialties
                            </h1>
                            <hr className="accent-line"></hr>
                        </Col>
                    </Row>
                    <br/>
                    <Skill img={require('../assets/app_dev.png')} skillName="iOS Development" skillDescription={skillDescription.iosDev} darkMode={isDarkMode}/>
                    <Skill img={require('../assets/web_dev.png')} skillName="Web Development" skillDescription={skillDescription.webDev} darkMode={isDarkMode}/>
                    <Skill img={require('../assets/cloud_consulting.png')} skillName="Cloud Consulting" skillDescription={skillDescription.cloud} darkMode={isDarkMode}/>
                    <Skill img={require('../assets/business_solutions.png')} skillName="Business Solutions" skillDescription={skillDescription.business} darkMode={isDarkMode}/>
                    <Skill img={require('../assets/e-commerce.png')} skillName="E-Commerce" skillDescription={skillDescription.ecom} darkMode={isDarkMode}/>
                    <Skill img={require('../assets/ml.png')} skillName="Machine Learning" skillDescription={skillDescription.ml} darkMode={isDarkMode}/>
                    <Skill img={require('../assets/ar.png')} skillName="Augmented Reality" skillDescription={skillDescription.ar} darkMode={isDarkMode}/>

                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <h1 className={(isDarkMode) ? "dark section-title" : "light section-title"}>
                                Contact
                            </h1>
                            <hr className="accent-line"></hr>
                        </Col>
                    </Row>
                    </section>

                    <section id="contact">
                    <Row>
                        <Col lg={6} md={12} xs={12}>
                            <Row className="form-row">
                              <PhoneIcon style={ { color: "#92B8E6" } } />  <h5 className={ isDarkMode ? "dark" : "light"} style={ {paddingLeft: "20px"} }> 
                              <a className={ isDarkMode ? "dark" : "light"} href="tel:843-593-0120">(843) 593 0120</a>
                            </h5>
                            </Row>
                            <Row className="form-row">
                              <EmailIcon style={ { color: "#92B8E6" } } />  <h5 className={ isDarkMode ? "dark" : "light"} style={ {paddingLeft: "20px"} }> 
                              <a className={ isDarkMode ? "dark" : "light"} href="mailto:info@alphatier.io?Subject=alphatier.io%20Email%20Contact" target="_top">info@alphatier.io</a>
                              </h5>
                            </Row>
                            
                        </Col>
                        <Col lg={6} md={12} xs={12}>
                            <ContactForm darkMode={isDarkMode}></ContactForm>
                        </Col>
                    </Row>
                    </section>
                    

            </Container>

        <Footer darkMode={isDarkMode}/>
        </div>

    );
}

export default Header;
 
