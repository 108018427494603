import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Skill(props) {
    return (
        <Row className="skill-row">
            <Col  xs={12} md={12} lg={6}>
                <img className="right-img" src={props.img} />  
            </Col>
            <Col xs={12} md={12} lg={6} className="center-text-container">
                <span className={(props.darkMode) ? "dark" : "light"}>
                    <h2  >
                        {props.skillName}
                    </h2>
                    <p>{props.skillDescription}</p>
                </span>
            </Col>
        </Row>
    )
}

export default Skill