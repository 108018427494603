import React,  { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';

function ContactForm(props) {

  const [alert, setAlert] = useState({
    isHidden : true,
    severity: "",
    message: "",
    textColor: ""

  })

  const [ message, setMessage ] = useState({
    mName : "",
    email: "",
    telephone: "",
    messageText: ""
  });

  const [ btnDisabled, setBtnDisabled ] = useState(false)

  function handleChange(event) {
    const { name, value } = event.target;

    setMessage(prevMessage => {
      return {
        ...prevMessage,
        [name]: value
      };
    });

  }

  function hideAlert() {
    setAlert({
      isHidden: true
    })
  }

  function handleClick(event) {
    event.preventDefault()
    setBtnDisabled(true)
    if (!message.mName || !message.email || !message.messageText)  {
      setAlert({
        isHidden: false,
        severity: "error",
        message: "Please fill out the required fields.",
        textColor: "#F44336"
      })
      setBtnDisabled(false)
      return
    }
    const url = "https://memm16l6a1.execute-api.us-east-1.amazonaws.com/01/contact"

    var data = {
      name : message.mName,
      phone : message.telephone,
      email : message.email,
      desc : message.messageText
    }

    axios.post(url, data)
    .then(function (response) {
    setBtnDisabled(false)

    if (response.status === 200) {
      setMessage({
        mName: "",
        email: "",
        telephone: "",
        messageText: ""
      })

      setAlert({
        isHidden: false,
        severity: "success",
        message: "Message successfully sent, thank you.",
        textColor: "#4CAF50"
      })
    }
    })
    .catch(function (error) {
      setBtnDisabled(false)
      setAlert({
        isHidden: false,
        severity: "error",
        message: "Error sending message, please try again",
        textColor: "#F44336"
      })     
      
  });
    
  }

    return (
      <div >
        <Row >
            <Col xs={12} md={12} lg={12}>
              <form className="contact-form" noValidate autoComplete="off">
                <Row className="form-row">
                  <TextField name="mName" fullWidth="true" size="small" label="Name" variant="outlined" value={message.mName} onChange={handleChange} required/>
                </Row>
                <Row className="form-row">
                <TextField fullWidth name="email" size="small" type="email" label="Email" variant="outlined" value={message.email} onChange={handleChange} required />
                </Row>
                <Row className="form-row">
                <TextField fullWidth name="telephone" size="small" type="tel" label="Telephone"  variant="outlined" value={message.telephone} onChange={handleChange} />
                </Row>
                <Row className="form-row">
                <TextField fullWidth multiline name="messageText" rows="5" rowsMax="25" label="Message" variant="outlined" value={message.messageText} onChange={handleChange} required/>
                </Row>
                <Row className="form-row">
                <Alert onClose={hideAlert} style={alert.isHidden ? { display: 'none' } : {}} variant="outlined" severity={alert.severity}>
                  <span style={ {color: alert.textColor} }id="alertMessage">{alert.message}</span>
                </Alert>
                </Row>                
                <Row className="form-row">
                <Button
                    variant="contained"
                    color="#92B8E6"
                    endIcon={<Icon>send</Icon>}
                    onClick={handleClick}
                    disabled={btnDisabled}
                  >
                    Send
                  </Button>
                </Row>
              </form>
            </Col>
        </Row>
      </div>
        
    )
}

export default ContactForm;

