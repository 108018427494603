import React from 'react'

function Footer(props) {
    const year = new Date().getFullYear();
    return (
        <div>
            <footer className="foot">
                <p style={ { color: props.darkMode ? "#F8F9FA" : "#343A40" } }>alphatier ⓒ {year}</p>
            </footer> 
        </div>       
    )
}

export default Footer;